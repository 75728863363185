import React from 'react';
import PropTypes from 'prop-types';
import './css/About.css'; // Optional: Include a CSS file for styling

const subtitle = "Web Developer & History Enthusiast"

const title = "Mark Thomas"

const imageUrl="/mark.jpg" 

const bio=`I’m Mark, a passionate web developer with a knack for creating dynamic and engaging websites. With over five years of experience in front-end and back-end development, I love transforming complex problems into user-friendly solutions.

When I'm not coding, you can find me exploring the rich tapestry of history. I enjoy delving into the past and sharing fascinating stories that connect technology and historical events. My blog is a blend of tutorials on web development techniques and captivating articles that highlight historical milestones and their impact on modern society.

Join me as I navigate the intersection of code and history, and let’s explore how the lessons of the past can inspire the innovations of the future!`

const About = () => {
  return (
    <div className="about-container">
      <img src={imageUrl} alt="About Me" className="about-image" />
      <div className="about-content">
        <h1 className="about-title">{title}</h1>
        <h6 className="about-sub-title">{subtitle}</h6>
        <p className="about-bio">{bio}</p>
      </div>
    </div>
  );
};

About.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
};

export default About;