import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";
import About from "../components/AboutComponent";
import './css/styles.css'

import Layout from "../layouts";

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <div className="px-lg-8">
        <Helmet title={`About | ${config.website.title}`} />
        <About/>
      </div>
    </Layout>
  );
};

export default AboutPage;
